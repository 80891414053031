import accounting from 'accounting';
import React, { useState, useEffect, useMemo } from 'react';
import {
  AlertService,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Timing,
  PriceIndicator,
  TextBoxWithLabel,
  WandIcon,
} from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { event } from 'react-fullstory';
import { negotiationOrigin } from '../../enums';
import { AwardSummaryQuery, OfferListingsByInventoryQuery, StagedNegotiationSummaryQuery, trucklanePollingQuery } from '../../graphql/queries';
import {
  AwardOfferListing,
  UnawardOfferListing,
  UnawardAndNegotiateOfferListing,
  createNegotiationStagedListings,
  IgnoreOfferListings,
  RestoreOfferListings,
} from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import destinationType from '../../enums/staged-inventory-destination-type';
import styles from './offer-card-v2-styles';
import deleteCachedFieldsOnOfferReviewPollingQuery from '../../apollo/cache-helpers/delete-cached-fields-on-offer-review-polling-query';
import TrucklaneDetails from './offer-card-trucklane-details';
import OfferCardFooter from './offer-card-footer';
import OfferCardNegotiationsDetails from './offer-card-negotiation-details';

const useStyles = createUseStyles(styles, { name: 'OfferCard' });

const OfferCardV2 = ({ disabled, setOfferCardsEnabledState, offerInfo, user, fullstoryTag, showSuggestions = false }) => {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(0);
  const [pricePerCase, setPricePerCase] = useState(0);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const initialPricePerCase = offerInfo.totalPrice / offerInfo.quantity;
  const [activeTruckLane, setActiveTruckLane] = useState(offerInfo.activeTruckLane);
  const refetchQueries = (negotiation = false) => {
    const queries = [
      {
        query: AwardSummaryQuery,
      },
      {
        query: OfferListingsByInventoryQuery,
        variables: { inventoryIds: [offerInfo.inventory._id] },
      },
    ];
    if (negotiation) {
      queries.push({
        query: StagedNegotiationSummaryQuery,
        variables: { buyerSiteIds: [offerInfo.offer.buyerSite._id] },
      });
    }
    if (showSuggestions) {
      queries.push({
        query: trucklanePollingQuery,
        variables: { pollingDetails: { origin: offerInfo.inventory.siteId, truckType: offerInfo.inventory.truckType } },
      });
    }
    return queries;
  };

  const [unawardOfferListing, { loading: unawardLoading }] = useMutation(UnawardOfferListing);
  const [awardOfferListing, { loading: awardLoading }] = useMutation(AwardOfferListing);
  const [ignoreOfferListing, { loading: ignoreLoading }] = useMutation(IgnoreOfferListings);
  const [restoreOfferListing, { loading: restoreLoading }] = useMutation(RestoreOfferListings);
  const [negotiateOfferListing, { loading: negotiateLoading }] = useMutation(createNegotiationStagedListings);
  const [unawardAndNegotiateOfferListing, { loading: unawardAndNegotiateLoading }] = useMutation(UnawardAndNegotiateOfferListing);
  const isAwarded = offerInfo.status === 'AWARDED';
  const initialQuantity = isAwarded ? offerInfo.quantity : Math.min(offerInfo.quantity, offerInfo.inventory.availableQuantity);

  useEffect(() => {
    setQuantity(isAwarded ? offerInfo.quantity : Math.min(offerInfo.quantity, offerInfo.inventory.availableQuantity));
    setPricePerCase(offerInfo.totalPrice / offerInfo.quantity);
    setActiveTruckLane(offerInfo.activeTruckLane);
    refetchQueries();
  }, [offerInfo.quantity, offerInfo.totalPrice, offerInfo.inventory.availableQuantity, offerInfo.activeTruckLane]);

  const isNegotiationStaged = offerInfo?.negotiation?.status === 'STAGED';

  const isNegotiationPublished = offerInfo?.negotiation?.status === 'PUBLISHED';

  const isNegotiationReceived = offerInfo?.negotiation?.status === 'RECEIVED';

  const isIgnored = offerInfo.status === 'IGNORED';

  const isDonation = offerInfo?.destinationType === destinationType.donation;

  const isSuggested = showSuggestions && !isAwarded && offerInfo?.suggestions?.award?.suggested;

  const actionInProgress = awardLoading || unawardLoading;

  const ignoreInProgress = ignoreLoading || restoreLoading;

  const negotiationInProgress = negotiateLoading || unawardAndNegotiateLoading;

  const loadingInProgress = actionInProgress || ignoreInProgress || negotiationInProgress;

  const canAward = () => {
    if (quantity > offerInfo.inventory.availableQuantity) return false;
    if (quantity === 0) return false;
    if (!(pricePerCase > 0) && !isDonation) return false;
    if (typeof pricePerCase !== 'number') return false;
    return true;
  };

  const error = (message) => {
    AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{message}</span> });
  };

  const genericMessageGenerator = (message) =>
    `Sorry there was an error ${message} your offer. If this problem persists, please contact a Spoiler Alert Administrator to help you.`;

  const handleAward = Timing.throttle(() => {
    if (loadingInProgress || !canAward()) return;
    setOfferCardsEnabledState(true);
    awardOfferListing({
      variables: { id: offerInfo._id, quantity, costPerCase: pricePerCase.toString() },
      update: (cache) => {
        deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters']);
        deleteCachedFieldsOnOfferReviewPollingQuery(cache);
      },
      refetchQueries: refetchQueries(),
    })
      .then((res) => {
        if (res.data.awardOfferListing.errors && res.data.awardOfferListing.errors.length > 0) {
          error(res.data.awardOfferListing.errors[0].message);
        }
        event(`Offer was awarded ${fullstoryTag || ''}`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() => {
        error(
          'We were unable to award this offer due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.'
        );
      })
      .finally(() => {
        setOfferCardsEnabledState(false);
        setShowWarningModal(false);
      });
  }, 1000);

  const handleUnaward = Timing.throttle(() => {
    if (loadingInProgress) return;
    setQuantity(offerInfo.originalQuantity);
    unawardOfferListing({
      variables: { id: offerInfo._id },
      update: (cache) => deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters']),
      refetchQueries: refetchQueries(),
    })
      .then(() => {
        event(`Offer was unawarded ${fullstoryTag || ''}`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() => {
        error(genericMessageGenerator('unawarding'));
      });
  }, 1000);

  const handleIgnore = Timing.throttle(() => {
    if (loadingInProgress) return;
    ignoreOfferListing({
      variables: { offerListingIds: [offerInfo._id] },
      refetchQueries: refetchQueries(),
    })
      .then((ignoreResp) => {
        if (ignoreResp.data?.ignoreOfferListingsById?.errors?.length) return error(ignoreResp.data?.ignoreOfferListingsById?.errors[0].message);
        return event(`Offer was ignored`, {
          userId: user._id,
          offerId: offerInfo._id,
          offerStatus: offerInfo.status,
          negotiationStatus: offerInfo?.negotiation?.status,
        });
      })
      .catch(() => error(genericMessageGenerator('ignoring')));
  }, 1000);

  const handleRestore = Timing.throttle(() => {
    if (loadingInProgress) return;
    restoreOfferListing({
      variables: { offerListingIds: [offerInfo._id] },
      refetchQueries: refetchQueries(),
    })
      .then((restoreResp) => {
        if (restoreResp.data?.restoreOfferListingsById?.errors?.length) return error(restoreResp.data?.restoreOfferListingsById?.errors[0].message);
        return event(`Offer was restored`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() => error(genericMessageGenerator('restoring')));
  }, 1000);

  const handleNegotiation = Timing.throttle(() => {
    if (loadingInProgress) return;
    negotiateOfferListing({
      variables: {
        counterOffers: [{ offerListingId: offerInfo._id, counterOfferAnchor: 'Default' }],
        negotiationOrigin: negotiationOrigin.OFFER_CARD_PRIMARY,
      },
      refetchQueries,
    })
      .then((response) => {
        if (response.data?.createNegotiationStagedListings?.errors?.length)
          return error(response.data?.createNegotiationStagedListings?.errors[0].message);
        const messageInfo = `Negotiation started with ${offerInfo.offer.buyerSite.siteName}`;
        AlertService.alert({ type: 'success', message: <span>{messageInfo}</span>, autoDismiss: true });
        return event(`OC - Negotiation Staged`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() => {
        error(genericMessageGenerator('negotiating'));
      });
  }, 1000);

  const handleUnawardAndNegotiate = Timing.throttle(() => {
    if (loadingInProgress) return;
    unawardAndNegotiateOfferListing({
      variables: { id: offerInfo._id, negotiationOrigin: negotiationOrigin.OFFER_CARD_PRIMARY },
      refetchQueries,
    })
      .then((response) => {
        if (response.data?.unawardAndNegotiateOfferListing?.errors?.length)
          return error(response.data?.unawardAndNegotiateOfferListing?.errors[0].message);
        const messageInfo = `Negotiation started with ${offerInfo.offer.buyerSite.siteName}`;
        AlertService.alert({ type: 'success', message: <span>{messageInfo}</span>, autoDismiss: true });
        return event(`Offer was unawarded and negotiated`, {
          userId: user._id,
          offerId: offerInfo._id,
          negotiationOrigin: negotiationOrigin.OFFER_CARD_PRIMARY,
          counterOfferAnchor: 'Manual',
        });
      })
      .catch(() => error(genericMessageGenerator('unawarding')));
  }, 1000);

  const checkAwardQuantity = () => {
    if (quantity === 0) return;
    if (quantity > offerInfo.listing.originalQuantity) {
      setShowWarningModal(true);
    } else {
      handleAward();
    }
  };

  const adjustQuantity = (newQuantity) => {
    setQuantity(accounting.unformat(newQuantity));
  };

  const adjustPricePerCase = (newPricePerCase) => {
    setPricePerCase(accounting.unformat(newPricePerCase));
  };

  const getLogisticsRelationshipName = () => {
    if (!offerInfo.logisticsRelationshipName) return '';
    return offerInfo.logisticsRelationshipName
      .replace('Delivery to ', '')
      .replace('Pickup for ', '')
      .replace(/^Default$/, '');
  };

  const isDisabled = disabled || (offerInfo.inventory.availableQuantity === 0 && !isAwarded);

  const wrapperClass = () => {
    let classString = `${classes.offerCardWrapper}`;
    if (isAwarded) classString += ' awarded';
    else if (isNegotiationPublished) classString += ' negotiated';
    else if (isNegotiationStaged) classString += ' negotiationStaged';
    else if (isNegotiationReceived) classString += ' negotiationReceived';
    else if (isDisabled) classString += ' disabled';
    if (isIgnored) classString = `${classes.offerCardWrapper} ignored`;
    return classString;
  };

  const getAdditionalSuggestedInfo = () => {
    return isSuggested ? `SUGGESTED QTY: ${accounting.formatNumber(offerInfo.suggestions.award.quantity)}` : null;
  };

  const getQuantity = () => {
    if ((!offerInfo.inventory.availableQuantity && isAwarded) || !quantity) return accounting.formatNumber(initialQuantity);
    if (!offerInfo.inventory.availableQuantity) return '0';
    return accounting.formatNumber(quantity);
  };

  const ignoredClass = isIgnored ? 'ignored' : '';

  const renderInputs = () => (
    <div className={`${classes.offerCardEditable} ${ignoredClass}`}>
      <TextBoxWithLabel
        isValid={(value) => {
          const number = accounting.unformat(value);
          return number <= offerInfo.inventory.availableQuantity && number > 0;
        }}
        onChange={(newQuantity) => adjustQuantity(newQuantity)}
        disabled={isAwarded || isDisabled || isIgnored}
        type="text"
        label="QTY"
        value={getQuantity()}
        denominator={accounting.formatNumber(offerInfo.listing.originalQuantity)}
        className={isDonation ? classes.donationTextboxWithDenominator : classes.textboxWithDenominator}
        additionalInfoTop={getAdditionalSuggestedInfo()}
        additionalInfoTopClassName={classes.textboxWithNumerator}
        additionalInfoClassName={classes.textboxWithDenominator}
        id={'QuantityTextBox'}
      />
      {!isDonation && (
        <TextBoxWithLabel
          isValid={(value) => accounting.unformat(value) > 0}
          onChange={(newPricePerCase) => adjustPricePerCase(newPricePerCase)}
          disabled={isAwarded || isDisabled || isIgnored}
          type="text"
          label="BID"
          value={pricePerCase !== 0 ? accounting.formatMoney(pricePerCase) : accounting.formatMoney(initialPricePerCase)}
          additionalInfoClassName={classes.additionalInfoPrice}
          id={'PriceTextBox'}
        />
      )}
    </div>
  );

  const renderNegotiationReceivedText = useMemo(() => {
    const unitPrice = offerInfo?.negotiation?.buyerResponseUnitPrice || offerInfo.totalPrice / offerInfo.quantity || 0;
    if (unitPrice > offerInfo?.negotiation?.stagedUnitPrice) {
      return 'COUNTER EXCEEDED';
    }
    if (unitPrice === offerInfo?.negotiation?.stagedUnitPrice) {
      return 'COUNTER MET';
    }
    if (unitPrice > offerInfo.originalTotalPrice / offerInfo.originalQuantity) {
      return 'PARTIAL INCREASE';
    }
    if (unitPrice < offerInfo.originalTotalPrice / offerInfo.originalQuantity) {
      return 'REDUCED BID';
    }
    return 'NO CHANGE';
  }, [offerInfo?.negotiation]);

  return (
    <div className={wrapperClass()} data-cy="offer-card-wrapper" data-testid="offer-card">
      {/* This is all just the header bar */}
      {!isAwarded && isNegotiationStaged && !isDonation && !isIgnored && (
        <div className={`${classes.offerCardNegotiationTag} ${classes.offerCardNegotiationTagDark}`}>Negotiation Staged</div>
      )}
      {!isAwarded && isNegotiationPublished && !isIgnored && <div className={classes.offerCardNegotiationTag}>Negotiation Published</div>}
      {!isAwarded && isNegotiationReceived && !isIgnored && (
        <div className={`${classes.offerCardNegotiationTag} ${classes.offerCardNegotiationTagBlue}`}>
          Negotiation Received - {renderNegotiationReceivedText}
        </div>
      )}
      {isAwarded && <div className={classes.offerCardAwardedTag}>Awarded</div>}
      {isIgnored && <div className={classes.offerCardIgnoredTag}>Ignored</div>}

      <div className={`${classes.offerCardHeader} ${ignoredClass}`}>
        <div className={classes.offerCardTitles}>
          <h4>{`${isDonation ? 'Donation: ' : ''}${offerInfo.offer.buyerSite.siteName}`}</h4>
          {getLogisticsRelationshipName() !== '' && <h5>{getLogisticsRelationshipName()}</h5>}
        </div>
        {isSuggested && <WandIcon className={classes.wandIcon} />}
      </div>
      {renderInputs()}
      <div style={{ padding: '0px 16px 19px' }}>
        {/* On donations theres no price indicator */}
        {!isDonation && (
          <PriceIndicator
            suggestedPrice={offerInfo.listing.stagedUnitPrice}
            reservePrice={offerInfo.activeReservePrice}
            pricePerCase={pricePerCase || initialPricePerCase}
            tooltipText={
              <div>
                <div>{`${accounting.formatNumber((pricePerCase / offerInfo.listing.stagedUnitPrice) * 100, 0)}% of suggested price`}</div>
                {offerInfo.activeReservePrice > 0 && (
                  <div>{`${accounting.formatNumber((pricePerCase / offerInfo.activeReservePrice) * 100, 0)}% of reserve price`}</div>
                )}
              </div>
            }
          />
        )}
      </div>
      {(isNegotiationStaged || isNegotiationPublished || isNegotiationReceived) && (
        <OfferCardNegotiationsDetails
          offerInfo={offerInfo}
          user={user}
          isNegotiationReceived={isNegotiationReceived}
          chevronArrowDirection={isAwarded || isIgnored ? 'down' : 'up'}
        />
      )}
      <TrucklaneDetails
        activeTruckLane={activeTruckLane}
        offerInfo={offerInfo}
        isAwarded={isAwarded}
        quantity={quantity}
        user={user}
        pricePerCase={pricePerCase}
      />
      <OfferCardFooter
        handleUnaward={handleUnaward}
        handleUnawardAndNegotiate={handleUnawardAndNegotiate}
        checkAwardQuantity={checkAwardQuantity}
        ignoredClass={ignoredClass}
        offerInfo={offerInfo}
        quantity={quantity}
        pricePerCase={pricePerCase}
        isAwarded={isAwarded}
        isDonation={isDonation}
        isIgnored={isIgnored}
        actionInProgress={actionInProgress}
        ignoreInProgress={ignoreInProgress}
        negotiationInProgress={negotiationInProgress}
        handleRestore={handleRestore}
        handleIgnore={handleIgnore}
        canAward={canAward}
        disabled={disabled}
        refetchQueries={refetchQueries}
        UnawardAndNegotiateOfferListing={UnawardAndNegotiateOfferListing}
        createNegotiationStagedListings={createNegotiationStagedListings}
        handleNegotiation={handleNegotiation}
        user={user}
      />
      <Modal onHide={() => setShowWarningModal(false)} open={showWarningModal} closeOnEsc closeOnOutsideClick modalWidth={500}>
        <ModalContent>
          <h2 className={classes.offerCardModalTitle}>Warning</h2>
          <p>The amount you are trying to award is greater than the amount listed. Do you want to award anyway?</p>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={handleAward} primary>
            Award
          </Button>
          <Button type="button" onClick={() => setShowWarningModal(false)} link>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

OfferCardV2.propTypes = {
  disabled: PropTypes.bool,
  setOfferCardsEnabledState: PropTypes.func,
  inventoryId: PropTypes.string,
  offerInfo: PropTypes.object,
  user: PropTypes.object,
  fullstoryTag: PropTypes.string,
  showSuggestions: PropTypes.bool,
};

export default OfferCardV2;
