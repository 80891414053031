import React, { Suspense, useMemo, lazy } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Theme,
  Button,
  icons,
  SideDropArrowIcon,
  DryIcon,
  RefrigeratedIcon,
  FrozenIcon,
  PickupIcon,
  DeliveryIcon,
  ActiveLaneIcon,
  ColorBadge,
  AlertService,
  Timing,
  Loading,
} from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { useMutation, useQuery } from '@apollo/client';
import { event } from 'react-fullstory';
import safeGet from '../../utilities/safe-get';
import TrucklaneMetricCard from './trucklane-metrics-card';
import { initialDrilldownMetrics, generateMetrics } from './trucklane-utility';
import { offerlistingStatuses } from '../../enums';
import { OfferListingsByInventoryQuery, OfferTrucklaneV2Query, trucklanePollingQuery } from '../../graphql/queries';
import { IgnoreOfferListings, RestoreOfferListings } from '../../graphql/mutations';
import { Store } from '../../store';
import RecalculationBanner from './recalculation-banner';
import TrucklaneDrilldownTables from './trucklane-drilldown-tables';

const TruckRouteMap = lazy(() => import('../../components/truck-route-map/truck-route-map'));

const styles = {
  drilldownContainer: {
    overflowY: 'auto',
    padding: '0px 24px',
  },
  rowContainer: {
    display: 'flex',
    marginTop: 24,
    marginBottom: 36,
  },
  row: {
    display: 'flex',
  },
  wrapRow: {
    display: 'flex',
    justifyContent: 'space-between',
    containerType: 'inline-size',
  },
  '@container (max-width: 850px)': {
    wrapRow: {
      flexWrap: 'wrap',
    },
  },
  rowClose: {
    display: 'flex',
  },
  rowBreak: {
    marginTop: '12px',
  },
  flexGrow: {
    containerType: 'inline-size',
    flex: 1,
    marginRight: 24,
  },
  cardOverview: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px',
    '& h1': {
      fontSize: '14px',
      fontWeight: '500',
      margin: 0,
    },
    '& svg': {
      marginRight: '8px',
    },
    '& h2': {
      fontSize: '18px',
      fontWeight: 'normal',
      margin: 0,
    },
  },
  overviewLogistics: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: 0,
      fontSize: '16px',
      color: Theme.grey,
      fontWeight: 'normal',
      marginRight: 24,
    },
  },
  activeLane: {
    border: `1px solid ${Theme.primaryColor}`,
    backgroundColor: Theme.green5,
    borderRadius: '2px',
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: Theme.primaryColor,
      width: 'fit-content',
      padding: '2px 8px 2px 0px',
      marginBottom: 0,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '0.75rem',
      whiteSpace: 'nowrap',
    },
    '& svg': {
      stroke: Theme.primaryColor,
      fill: 'transparent',
      width: 20,
      height: 20,
      marginLeft: 8,
    },
  },
  logisticsIcon: {
    height: 16,
    width: 16,
    fill: Theme.grey,
  },
  overviewActions: {
    marginTop: 24,
    display: 'flex',
    '& button': {
      marginRight: 8,
    },
  },
};
const useStyles = createUseStyles(styles);

const TrucklaneDrilldown = ({ user, trucklaneId, offers, recalculation, handleDrilldownRecalculation, loading, history }) => {
  const aggregateOffer = offers[0];
  const classes = useStyles();
  const [ignoreOffersMutation, { loading: ignoreOffersLoading }] = useMutation(IgnoreOfferListings);
  const [restoreOffersMutation, { loading: restoreOffersLoading }] = useMutation(RestoreOfferListings);
  const { data, loading: trucklaneQueryLoading } = useQuery(OfferTrucklaneV2Query, {
    variables: {
      buyerSiteId: aggregateOffer.buyerSiteId,
      truckType: aggregateOffer.truckType,
      logisticsTerm: aggregateOffer.logisticsTerm,
      transactionLocationId: aggregateOffer.transactionLocationId,
      sellerLocationId: aggregateOffer.sellerLocationId,
      buyerDestinationId: aggregateOffer.buyerDestinationId,
    },
    fetchPolicy: 'network-only',
  });
  const logisticsIcons = {
    Pickup: PickupIcon,
    Delivery: DeliveryIcon,
  };
  const truckTypeIcons = {
    Dry: DryIcon,
    Refrigerated: RefrigeratedIcon,
    Frozen: FrozenIcon,
  };
  const LogisticsIcon = logisticsIcons[aggregateOffer.logisticsTerm];
  const TruckIcon = truckTypeIcons[aggregateOffer.truckType];
  const invalidTrucklanesExist = !!Store.invalidTrucklaneChannels.size;

  const metrics = useMemo(() => {
    if (data?.offerListingsByTrucklaneQuery?.data?.length) {
      const offerData = data.offerListingsByTrucklaneQuery.data.map((trucklane) => {
        return { ...trucklane.offerListings[0], inventory: trucklane.inventory };
      });
      return generateMetrics([{ id: 'drilldown', offers: offerData }], initialDrilldownMetrics, recalculation);
    }
    return initialDrilldownMetrics;
  }, [recalculation, data]);

  const { awardedInventory, fillupInventory, suggestedInventory, acceptedInventory, ignoredInventory } = useMemo(() => {
    const awardedInv = [];
    const fillupInv = [];
    const acceptedInv = [];
    const suggestedInv = [];
    const ignoredInv = [];
    const reconfigedData = (data?.offerListingsByTrucklaneQuery?.data || []).reduce((newSet, row) => {
      row.offerListings.forEach((ol) => {
        if (ol.buyerSiteId === aggregateOffer.buyerSiteId)
          newSet.push({
            ...row.inventory,
            offerListing: ol,
            competingOffers: row.competingOffers,
            id: row.inventory._id,
            _id: `${row.inventory._id}_${ol._id}`,
          });
      });
      return newSet;
    }, []);
    reconfigedData.forEach((row) => {
      if (row.offerListing.status === offerlistingStatuses.IGNORED) {
        ignoredInv.push(row);
      } else if (row.offerListing.status === offerlistingStatuses.AWARDED) {
        awardedInv.push(row);
      } else if (row.offerListing.status === offerlistingStatuses.ACCEPTED) {
        acceptedInv.push(row);
      } else if (row.offerListing.suggestions.award.suggested) {
        suggestedInv.push(row);
      } else {
        fillupInv.push(row);
      }
    });

    return {
      awardedInventory: awardedInv,
      fillupInventory: fillupInv.filter((inv) => !awardedInv.map((awardInv) => awardInv.id).includes(inv.id)),
      acceptedInventory: acceptedInv,
      suggestedInventory: recalculation ? [] : suggestedInv,
      ignoredInventory: ignoredInv,
    };
  }, [data, recalculation]);

  const { activeTruckLane, ignoredOffers } = useMemo(() => {
    const active = offers.filter((offer) => [offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status));
    const ignored = offers.filter((offer) => [offerlistingStatuses.IGNORED].includes(offer.status));
    return { activeTruckLane: active, ignoredOffers: ignored };
  }, [offers]);

  const displayLogisticsTerm = () => {
    return aggregateOffer.logisticsTerm === 'Pickup' ? (
      <div className={classes.overviewLogistics}>
        <LogisticsIcon className={classes.logisticsIcon} />
        <p>{aggregateOffer.logisticsTerm}</p>
      </div>
    ) : (
      <div className={classes.overviewLogistics}>
        <LogisticsIcon className={classes.logisticsIcon} />
        <p>
          {aggregateOffer.logisticsTerm} - {accounting.formatNumber(aggregateOffer.trucklaneDistance)} miles
        </p>
        <p>{accounting.formatMoney(aggregateOffer.trucklaneCost)} Est. Cost</p>
      </div>
    );
  };

  const ignoreOffers = Timing.throttle(() => {
    const matchedOffers = [
      ...fillupInventory.filter((inv) => inv.offerListing.status === offerlistingStatuses.ACTIVE),
      ...suggestedInventory.filter((inv) => inv.offerListing.status === offerlistingStatuses.ACTIVE),
    ];
    ignoreOffersMutation({
      variables: { offerListingIds: matchedOffers.map((inv) => inv.offerListing._id) },
      refetchQueries: [
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
        {
          query: OfferListingsByInventoryQuery,
          variables: { inventoryIds: matchedOffers.map((inv) => inv.id) },
        },
      ],
    }).then((ignoreResp) => {
      if (ignoreResp.data?.ignoreOfferListingsById?.errors?.length)
        AlertService.alert({
          type: 'warning',
          autoDismiss: true,
          message: <span>{ignoreResp.data?.ignoreOfferListingsById?.errors[0].message}</span>,
        });
      event(`Offers were ignored in trucklane drilldown`, {
        userId: user._id,
      });
    });
  }, 1000);

  const restoreOffers = Timing.throttle(() => {
    const matchedOffers = ignoredInventory.filter((inv) => inv.offerListing.status === offerlistingStatuses.IGNORED);
    restoreOffersMutation({
      variables: { offerListingIds: matchedOffers.map((inv) => inv.offerListing._id) },
      refetchQueries: [
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
        {
          query: OfferListingsByInventoryQuery,
          variables: { inventoryIds: matchedOffers.map((inv) => inv.id) },
        },
      ],
    }).then((restoreResp) => {
      if (restoreResp.data?.restoreOfferListingsById?.errors?.length)
        AlertService.alert({
          type: 'warning',
          autoDismiss: true,
          message: <span>{restoreResp.data?.restoreOfferListingsById?.errors[0].message}</span>,
        });
      event(`Offers were restored in trucklane drilldown`, {
        userId: user._id,
      });
    });
  }, 1000);

  return (
    <div className={classes.drilldownContainer}>
      <div className={classes.rowContainer}>
        <div className={classes.flexGrow}>
          <div className={classes.cardOverview}>
            <h1>{aggregateOffer.buyerSiteName}</h1>
            <h2>{aggregateOffer.inventory?.siteName}</h2>
            <div className={classes.rowClose}>
              <SideDropArrowIcon />
              <h2>{aggregateOffer.buyerDestinationName}</h2>
            </div>
            <div className={`${classes.overviewLogistics} ${classes.rowBreak}`}>
              <TruckIcon className={classes.logisticsIcon} />
              <p data-testid={'card-truck-type'}>{aggregateOffer.truckType}</p>
            </div>
            {displayLogisticsTerm()}
            <div className={`${classes.row} ${classes.rowBreak}`}>
              {activeTruckLane.length > 0 && (
                <div className={classes.activeLane}>
                  <ActiveLaneIcon /> <p>ACTIVE TRUCK LANE</p>
                </div>
              )}
              {ignoredOffers.length > 0 && <ColorBadge theme={{ color: Theme.greyDark, backgroundColor: Theme.grey10 }}>IGNORED OFFERS</ColorBadge>}
            </div>
          </div>
          <div className={classes.wrapRow}>
            {metrics.map((metric, index) => (
              <TrucklaneMetricCard
                key={index}
                title={metric.title}
                current={metric.current}
                projected={metric.projected}
                loading={loading || trucklaneQueryLoading}
              />
            ))}
          </div>
          <div className={classes.overviewActions}>
            <Button
              loadingText="Ignoring Offers"
              icon={icons.ignore}
              secondary
              onClick={ignoreOffers}
              loading={ignoreOffersLoading}
              disabled={ignoreOffersLoading || (!suggestedInventory.length && !fillupInventory.length)}
            >
              Ignore Offers
            </Button>
            <Button
              loadingText="Restoring Offers"
              icon={icons.restore}
              secondary
              onClick={restoreOffers}
              loading={restoreOffersLoading}
              disabled={restoreOffersLoading || !ignoredInventory.length}
            >
              Restore Offers
            </Button>
          </div>
        </div>

        <Suspense fallback={<Loading loading={true} />}>
          <TruckRouteMap
            logisticsTerm={offers[0].logisticsTerm}
            origin={{
              lat: safeGet(data, 'offerListingsByTrucklaneQuery.data.0.originLocation.latitude', 0),
              long: safeGet(data, 'offerListingsByTrucklaneQuery.data.0.originLocation.longitude', 0),
            }}
            destination={{
              lat: safeGet(data, 'offerListingsByTrucklaneQuery.data.0.destinationLocation.latitude', 0),
              long: safeGet(data, 'offerListingsByTrucklaneQuery.data.0.destinationLocation.longitude', 0),
            }}
            trucklaneId={trucklaneId}
            loading={trucklaneQueryLoading}
          />
        </Suspense>
      </div>
      <TrucklaneDrilldownTables
        user={user}
        history={history}
        recalculation={recalculation}
        trucklaneId={trucklaneId}
        loading={loading || trucklaneQueryLoading}
        awardedInventory={awardedInventory}
        fillupInventory={fillupInventory}
        suggestedInventory={suggestedInventory}
        acceptedInventory={acceptedInventory}
        aggregateOffer={aggregateOffer}
      />
      {invalidTrucklanesExist && <RecalculationBanner recalculateAction={() => handleDrilldownRecalculation(trucklaneId)} />}
    </div>
  );
};

TrucklaneDrilldown.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  trucklaneId: PropTypes.string,
  offers: PropTypes.arrayOf(PropTypes.object),
  recalculation: PropTypes.bool,
  handleDrilldownRecalculation: PropTypes.func,
  loading: PropTypes.bool,
};

export default TrucklaneDrilldown;
